<template>
    <div class="text-center login">
      <b-row class="login-contend m-0">
        <b-col sm="12" md="8" lg="6" xl="4" class="">
          <b-card class="p-0 p-md-2 ">
            <div
              class="w-100 d-lg-flex align-items-center justify-content-center px-5"
            >
              <b-img
                fluid
                src="@/assets/images/logo/stocks_easy_logo.png"
                alt="Login V2"
              />
            </div>
            <b-card-title class="my-2 font-weight-bold" title-tag="h2">
              Welcome to Stockseasy!
            </b-card-title>
            <b-card-text class="mb-2">
             Create an account or login
            </b-card-text>
  
            <!-- form -->
            <validation-observer ref="loginForm" v-slot="{ handleSubmit }">
              <b-form
                class="auth-login-form mt-2 text-left"
                @submit.prevent="handleSubmit(signUp)"
              >

               <!-- user-name -->
               <InputField
                  :id="'User Name'"
                  :placeholder="'User Name'"
                  :describedby="'user-name-feedback'"
                  :label="'User Name'"
                  :name="'User Name'"
                  :rules="{ required: true }"
                  :modelValue="name"
                  @input="(val) => (name = val)"
                />

                
                <!-- email -->
                <InputField
                  :id="'email'"
                  :placeholder="'Email'"
                  :describedby="'email-feedback'"
                  :label="'Email'"
                  :name="'Email'"
                  :rules="{ required: true, email: true }"
                  :modelValue="email"
                  @input="(val) => (email = val)"
                />

                 <!-- email -->
                 <InputField
                  :id="'phone'"
                  :placeholder="'Phone'"
                  :describedby="'phone-feedback'"
                  :label="'Phone Number'"
                  :name="'Phone number'"
                  :rules="{ required: true, integer: true }"
                  :modelValue="phone"
                  @input="(val) => (email = val)"
                />
  
                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
  
                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    I agree to privacy policy & terms
                  </b-form-checkbox>
                </b-form-group>
  
                <!-- submit buttons -->
                <b-button class="mt-3 p-1" type="submit" variant="primary" block>
                  Sign Up
                </b-button>
              </b-form>
            </validation-observer>
  
            <b-card-text class="text-center mt-2">
              <span>Already have an account? </span>
              <b-link :to="{ name: 'login' }">
                <span>&nbsp;Sign in instead</span>
              </b-link>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </template>
  <script>
  import { ref, computed } from "@vue/composition-api";
  import InputField from "@/views/components/form/InputField.vue";
  
  /* eslint-disable global-require */
  export default {
    components: {
      InputField,
    },
    setup(props,context) {
    	const router = context.root.$router;
      const email = ref("");
      const name = ref("");
      const password = ref("");
      const phone = ref("");
      const status = ref("");
      const passwordFieldType = ref("password");
      const passwordToggleIcon = computed(() =>
        passwordFieldType.value === "password" ? "EyeIcon" : "EyeOffIcon"
      );
      const togglePasswordVisibility = () => {
        passwordFieldType.value =
          passwordFieldType.value === "password" ? "text" : "password";
      };
      const signUp = () => {
				router.push('/clients')
			}
      return {
        status,
        passwordToggleIcon,
        togglePasswordVisibility,
        email,
        name,
        phone,
        password,
        passwordFieldType,
        signUp
      };
    },
  };
  </script>